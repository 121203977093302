<script setup>
</script>

<template>
  <div class="layout-taskpage">
    <slot />
  </div>
</template>

<style lang="postcss"></style>
